









































































import { Component, Vue } from "vue-property-decorator";
import LogoImage from "@/assets/logo.jpg";
import { config } from "./config";

@Component
export default class App extends Vue {
  private drawer = false;
  private logoImage = LogoImage;
  private instagramUrl = config.instagramUrl;
  private inquiryUrl = config.inquiryUrl;

  private items = [
    {
      title: "ホーム",
      icon: "mdi-home",
      to: "/",
    },
    {
      title: "教室の紹介",
      icon: "mdi-baguette",
      to: "about",
    },
    {
      title: "コースと料金",
      icon: "mdi-bread-slice-outline",
      to: "course",
    },
    {
      title: "初回の流れ",
      icon: "mdi-calendar",
      to: "reserve",
    },
  ];

  private secondItems = [
    {
      title: "お問い合わせフォーム",
      icon: "mdi-email",
      href: this.inquiryUrl,
    },
    {
      title: "Instagram",
      icon: "mdi-instagram",
      href: this.instagramUrl,
    },
  ];

  get bottomNavi() {
    return true;
  }
  goToIndexPage() {
    this.$router.push({ path: "/" });
  }
}
