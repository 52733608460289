



























import { Component, Vue } from "vue-property-decorator";
import CourseTimeline from "@/components/CourseTimeline.vue";
import PriceTable from "@/components/PriceTable.vue";
import ShakerIcon from "@/assets/icons/shaker.png";

@Component({ components: { CourseTimeline, PriceTable } })
export default class Course extends Vue {
  private shakerIcon = ShakerIcon;
}
