










































































































import { Component, Vue } from "vue-property-decorator";
import { config } from "../config";
import GreenIcon from "@/assets/icons/green.png";

@Component({})
export default class Inquiry extends Vue {
  private inquiryUrl = config.inquiryUrl;
  private greenIcon = GreenIcon;

  private firstLessonFlowItems = [
    {
      text: "お問い合わせフォームからご連絡ください",
    },
    {
      text: "日程や振込先情報をメールで返信します",
    },
    {
      text:
        "メール内容をご確認ください。FIRSTレッスンの料金お振り込みをお願いいたします。",
    },
    {
      text: "お振り込み確認後、教室の住所やFIRSTレッスンの内容についてメール",
    },
    {
      text: "当日お気をつけてお越しください",
    },
  ];

  private lessonFlowItems = [
    { text: "10時からスタート！ 午前9時45分から入室可能です。" },
    { text: "計量から焼き上がりまでで、約2時間です。" },
    {
      text:
        "出来上がったら自分で作ったパンの写真撮影。コーヒーとおやつでブレイクタイム！",
    },
    { text: "最後に次回の日程相談。13時までには終了します。" },
  ];

  private cautionItems = [
    {
      title: "対象者",
      textItems: [
        "女性・中学生以上 （女性同伴であれば男性も可能です。）",
        "小さなお子様を連れてのレッスンはご相談ください。",
      ],
    },
    {
      title: "レッスン時にお持ちいただくもの",
      textItems: ["エプロン", "ハンドタオル"],
    },
    {
      title: "レッスン日時",
      textItems: [
        "火・水・木・金曜日の10時から13時の3時間になります。",
        "土・日・月曜日、祝日はお休みです。",
        "FIRSTレッスンの日程はお問い合わせフォームで候補日を記入してください。メールでこちらから返答いたします。",
        "2回目以降のレッスン日の指定はLINEでのやり取りになります。",
      ],
    },
    {
      title: "レッスン料金の支払い方法",
      textItems: [
        "レッスン7日前までの銀行振込でお願いします。",
        "詳細については、レッスンのご予約が決まったらメールでご連絡いたします。",
      ],
    },
    {
      title: "教室の場所・アクセス",
      textItems: [
        "JR片倉駅から徒歩約15分になります。",
        "初めての方はお振り込み確定後にメールにて詳細を送らせて頂きます。防犯上ご理解お願いいたします。",
        "駐車場は2台分あります。使用される方は問い合わせ時にご連絡ください。",
      ],
    },
    {
      title: "キャンセルについて",
      textItems: [
        "キャンセルの方は材料費・準備などの関係がございますので、お早めにご連絡お願いいたします。",
      ],
    },
    {
      title: "レッスン中止について",
      textItems: [
        "悪天候や災害、その他の事由により講師がレッスン困難と判断した場合、レッスンを中止する場合がございます。その場合、すみやかにご連絡いたします。",
      ],
    },
    {
      title: "遅刻の場合",
      textItems: [
        "10分以上遅刻される方は、一緒に受講される方のご迷惑となりますのでキャンセルとさせていただきます。",
        "遅刻される場合はご連絡をお願いいたします。",
      ],
    },
    {
      title: "感染対策についてのお願い",
      textItems: [
        "レッスン中はできる限りマスクの着用をお願いいたします。",
        "発熱（37.5度以上）や咳などの風邪の症状、強い倦怠感、呼吸器系の症状など体調の悪い方はご連絡お願いいたします。",
      ],
    },
  ];

  get dynamicW() {
    console.log(this.$refs.inquiryWrapper);
    // @ts-ignore
    return (window.innerWidth > 0 ? window.innerWidth : screen.width) * 0.95;
  }
  get dynamicH() {
    return window.innerHeight * 0.9;
  }
}
