

































































import { Component, Vue } from "vue-property-decorator";
import LogoImage from "@/assets/logo.jpg";

import HomeImage0 from "@/assets/img/ks_044.jpg";
import HomeImage1 from "@/assets/img/ks_023.jpg";
import HomeImage2 from "@/assets/img/ks_038.jpg";
import HomeImage3 from "@/assets/img/ks_018.jpg";
import RainbowIcon from "@/assets/icons/rainbow.png";
import SunIcon from "@/assets/icons/sun.png";

@Component
export default class Home extends Vue {
  private logoImage = LogoImage;
  private rainbowIcon = RainbowIcon;
  private sunIcon = SunIcon;

  private homeImgItems = [
    { src: HomeImage0 },
    { src: HomeImage1 },
    { src: HomeImage2 },
    { src: HomeImage3 },
  ];

  clickMenu(menu: { to: string }) {
    console.log("click menu", menu);
    this.$router.push({ path: menu.to });
  }
}
