


























































































import { Component, Vue } from "vue-property-decorator";
import LessonFlow from "@/components/LessonFlow.vue";
import ImagePreview from "@/components/ImagePreview.vue";
import Profile from "@/components/Profile.vue";

import LessonStyleImage0 from "@/assets/img/ks_004.jpg";
import LessonStyleImage1 from "@/assets/img/ks_046.jpg";
import LessonStyleImage2 from "@/assets/img/ks_013.jpg";

import KodawariImage0 from "@/assets/img/ks_011.jpg";
import KodawariImage1 from "@/assets/img/ks_029.jpg";
import KodawariImage2 from "@/assets/img/ks_006.jpg";
import KodawariImage3 from "@/assets/img/ks_045.jpg";

import CourseImage0 from "@/assets/img/ks_035.jpg";
import CourseImage1 from "@/assets/img/ks_025.jpg";
import CourseImage2 from "@/assets/img/ks_041.jpg";
import CourseImage3 from "@/assets/img/ks_003.jpg";

@Component({ components: { LessonFlow, ImagePreview, Profile } })
export default class About extends Vue {
  private lessonStyleImageItems = [
    { src: LessonStyleImage0 },
    { src: LessonStyleImage1 },
    { src: LessonStyleImage2 },
  ];
  private kodawariImageItems = [
    { src: KodawariImage0 },
    { src: KodawariImage1 },
    { src: KodawariImage2 },
    { src: KodawariImage3 },
  ];
  private courseImageItems = [
    { src: CourseImage0 },
    { src: CourseImage1 },
    { src: CourseImage2 },
    { src: CourseImage3 },
  ];
}
