


























import { Component, Vue } from "vue-property-decorator";

@Component
export default class LessonFlow extends Vue {
  private items = [
    {
      color: "red lighten-2",
      icon: "mdi-baby-face-outline",
      userName: "生徒",
      title: "レッスン開始希望",
      content:
        "「お問い合わせ」ページからご連絡ください。（紹介者が必要になります。）初回のレッスンはFirstレッスンとなります。",
    },
    {
      color: "purple darken-1",
      icon: "mdi-chef-hat",
      userName: "教室",
      title: "問い合わせ応対",
      content:
        "お問い合わせ頂いた内容にメールで返信いたします。当教室の説明や、Firstレッスンの日程調整等を行います。Firstレッスンが始まる前まではメールでやり取りします。",
    },
    {
      color: "red lighten-2",
      icon: "mdi-chef-hat",
      userName: "生徒",
      title: "Firstレッスン参加希望",
      content: "メールで参加希望してください。日程を決める。",
    },
    {
      color: "purple darken-1",
      icon: "mdi-chef-hat",
      userName: "教室",
      title: "振込先通知",
      content: "振込先を教えるから振り込めよ",
    },
    {
      color: "red lighten-2",
      icon: "mdi-chef-hat",
      userName: "生徒",
      title: "振り込み処理",
      content: "5000円振り込む。その後メール",
    },

    {
      color: "purple darken-1",
      icon: "mdi-chef-hat",
      userName: "教室",
      title: "LINEアカウント共有",
      content:
        "振り込みを確認。LINEアカウント共有。以降、LINEでやり取り。教室の詳細な場所を連絡します。",
    },
    {
      color: "red lighten-2",
      icon: "mdi-chef-hat",
      userName: "生徒",
      title: "レッスン当日",
      content: "レッスン当日はxxxxとooooを持って、教室まで来てね。10時からだよ",
    },
  ];
}
