










































import { Component, Vue } from "vue-property-decorator";
import ProfileImage from "@/assets/img/portrait.jpg";
import BackgroundImage from "@/assets/img/a_13.jpg";
import SunIcon from "@/assets/icons/sun.png";

@Component({})
export default class Profile extends Vue {
  private bkImgSrc = BackgroundImage;
  private imgSrc = ProfileImage;
  private sunIcon = SunIcon;

  private lines = [
    { text: "名古屋生まれ" },
    { text: "管理栄養士学校卒業" },
    {
      text: "大手料理教室の講師として料理・ケーキ・パンの講師を務める（４年）",
    },
    { text: "名古屋の自宅でパン教室を開校（２年）" },
    {
      text:
        "出産子育てのため休業。アイシングクッキー・テーブルコーディネートについて学ぶ。",
    },
    {
      text:
        "2019年から八王子に拠点を移し、2021年3月31日に【Kitchen Sapori】を開業。 ",
    },
  ];
}
