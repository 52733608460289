



































































import { Component, Vue } from "vue-property-decorator";
import ImagePreview from "@/components/ImagePreview.vue";
import BasicBreadImage01 from "@/assets/img/basic_01.jpg";
import BasicBreadImage02 from "@/assets/img/basic_02.jpg";
import BasicBreadImage03 from "@/assets/img/basic_03.jpg";
import BasicBreadImage04 from "@/assets/img/basic_04.jpg";
import BasicBreadImage05 from "@/assets/img/basic_05.jpg";
import BasicBreadImage06 from "@/assets/img/basic_06.jpg";
import BasicBreadImage07 from "@/assets/img/basic_07.jpg";
import BasicBreadImage08 from "@/assets/img/basic_08.jpg";
import BasicBreadImage09 from "@/assets/img/basic_09.jpg";
import BasicBreadImage10 from "@/assets/img/basic_10.jpg";
import BasicBreadImage11 from "@/assets/img/basic_11.jpg";
import BasicBreadImage12 from "@/assets/img/basic_12.jpg";
import FirstBreadImage01 from "@/assets/img/first_01.jpg";
import { nextTick } from "vue/types/umd";

@Component({ components: { ImagePreview } })
export default class CourseTimeline extends Vue {
  private stepperModel = 1;
  private steps = [
    {
      id: 1,
      title: "FIRSTレッスン（1回）",
      subTitle: "¥5,000-",
      content:
        "１回目は、完全貸切プライベートレッスン。コーンマヨパンまたはツナマヨパンからおひとつメニューをお選びください。講師のデモを見ながら作る事ができるので、初めての方でも安心してご参加いただけます。",
      images: [
        {
          src: FirstBreadImage01,
          title: "コーンマヨパンとツナマヨパン",
        },
      ],
    },
    {
      id: 2,
      title: "BASICレッスン（合計12回）",
      subTitle: "チケット制",
      content:
        "計量から焼き上げまでパン作りの基礎の流れが学べます。1から順番にレベルアップしていきます。上の画像をタップすると12種類の画像が確認できます。",
      showSubListOl: true,
      showSubListUl: false,
      images: [
        {
          src: BasicBreadImage01,
          title: "ほうれん草とベーコンのキッシュブレット",
        },
        { src: BasicBreadImage02, title: "メープルアーモンド" },
        { src: BasicBreadImage03, title: "ツナチーズカレー" },
        { src: BasicBreadImage04, title: "季節のジャムとクリームチーズ" },
        { src: BasicBreadImage05, title: "オリーブソルト" },
        { src: BasicBreadImage06, title: "シナモンロール" },
        { src: BasicBreadImage07, title: "山形食パン" },
        { src: BasicBreadImage08, title: "Saporiの気まぐれピザ" },
        { src: BasicBreadImage09, title: "クリームチーズアンパン" },
        { src: BasicBreadImage10, title: "カマンベールノア" },
        { src: BasicBreadImage11, title: "塩バターロール" },
        { src: BasicBreadImage12, title: "ニコちゃんクリームパン" },
      ],
    },
    {
      id: 3,
      title: "STANDARDレッスン（合計12回）",
      subTitle: "チケット制",
      content:
        "Basic修了後には、Standardレッスンのご案内をいたします。基礎を活かしたさまざまなパンが学べ、レベルの高いパン作りを行います。",
      hideImages: true,
      showSubListOl: false,
      showSubListUl: true,
      images: [
        { title: "チョココロネ" },
        { title: "白パン" },
        { title: "ベーコンエピ" },
        { title: "カルツォーネ" },
        { title: "カンパーニュ" },
        { title: "セサミキッチン" },
        { title: "フォカッチャ" },
        { title: "ベルギーワッフル" },
        { title: "スイートブール" },
        { title: "ミルクフランス" },
        { title: "ガーリックバター" },
        { title: "メロンパン" },
      ],
    },
    {
      id: 4,
      title: "MASTERレッスン（合計6回）",
      subTitle: "チケット制",
      content:
        "新たな製法や粉を使い分け、更に上のレベルのパンを作っていきます。STANDARD（中級）コース修了後に参加可能になります。",
      hideImages: true,
      showSubListOl: false,
      showSubListUl: true,
      images: [
        { title: "フランスパン" },
        { title: "クロワッサン" },
        { title: "ベーグル" },
        { title: "カレーパン" },
        { title: "湯種食パン" },
        { title: "ドーナツ" },
      ],
    },
  ];

  async moveToTheStep(id: number) {
    this.stepperModel = id;
    let top = 100;

    switch (id) {
      case 1:
        top = 100;
        break;
      case 2:
        top = 200;
        break;
      case 3:
        top = 300;
        break;
      case 4:
        top = 400;
        break;
    }

    window.scrollTo({
      top,
      behavior: "smooth",
    });
  }
}
