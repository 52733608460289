








































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class ImagePreview extends Vue {
  @Prop({ default: [] })
  images!: { title?: string; src: string }[];

  @Prop({ default: 300 })
  height: number = 300;

  private dialog = false;

  private targetImageIdx = 0;

  imageGradient(index: number) {
    if (this.targetImageIdx === index) {
      return "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)";
    }

    return "";
  }
  openModal() {
    this.dialog = true;
    this.targetImageIdx = 0;
  }

  clickImage(imageIdx: number) {
    this.targetImageIdx = imageIdx;
  }
}
